.ui.form .disabled.field, .ui.form .disabled.fields .field, .ui.form .field :disabled {
  pointer-events: none;
  opacity: 0.9;
}

p {
  font-size: 1.1rem;
  line-height: 1.6rem;
  color: #4e4d4d
}

h2 {
  /* background: linear-gradient(transparent,transparent 40%,#f8d698 60%,#f8d698) */
  border-bottom: 0.2em dotted ;
  padding: 0.3em;
}

em {
  background: linear-gradient(transparent,transparent 40%,#f8d698 60%,#f8d698);
  font-style: normal;
}

li {
  margin-bottom: 1em;
}